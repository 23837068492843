import React, { useState, useRef } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import './CopyUrlClipboard.scss';
import Button from '@atoms/Button/Button';
import Title from '@atoms/Title/Title';

export default function CopyUrlClipboard(props) {
    const textRef = useRef(null);
    const inputRef = useRef(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [input, setInput] = useState(null);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const handleCopy = () => {
        inputRef.current.select();
        if (document !== undefined) {
            document.execCommand('copy');
            setSnackOpen(true);
        }
    };

    return (
        <div className="CopyUrlClipboard">
            <div className="CopyUrlClipboard--inner">
                <div className="cursor-pointer" onClick={handleCopy}>
                    {props.children}
                </div>
            </div>

            <input
                value={props.url}
                ref={inputRef}
                className="CopyUrlClipboard--input"
                readOnly
            />
            {/* Alert Message */}
            <Snackbar
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleSnackClose}
            >
                <Alert onClose={handleSnackClose} severity="success">
                    Link kopiert
                </Alert>
            </Snackbar>
        </div>
    );
}
